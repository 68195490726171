<template>
  <div
    class="bg-white border border-searchBorder border-t-0 relative text-center mt-5 md:mt-0"
  >
    <span
      :style="{ background: item.border }"
      class="h-1 block w-full absolute top-0"
    ></span>
    <div class="p-8 text-lg">
      <span
        class="bg-readmoreBG mx-auto my-4 h-16 w-16 flex items-center justify-center rounded-full"
        ><img loading="lazy" alt="" src="../../assets/user 1.svg" class="object-cover"
      /></span>
      <h2 class="text-newsText font-bold">
        {{ $i18n.locale == "EN" ? item.name_en : item.name_ar }}
      </h2>
      <h6 class="text-newsDescription font-medium">
        {{
          $i18n.locale == "EN"
            ? item.job_description_en
            : item.job_description_ar
        }}
      </h6>
    </div>
  </div>
</template>
<script>
export default {
  name: "StructureItem",
  props: ["item"],
};
</script>
