<template>
  <div id="Structure">
    <Header
      :title="$t('structure.header.title')"
      :description="$t('structure.header.description')"
    />
    <div class="container mx-auto px-5 lg:px-8 pb-5 pt-16">
      <div
        id="parent"
        class="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16 mx-auto"
      >
        <StructureItem
          class="lg:col-start-2"
          v-for="(emp, index) in head"
          :key="index"
          :item="{ ...emp, border: '#2D3032' }"
        />
      </div>
      <div
        id="child"
        class="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16 mx-auto"
      >
        <StructureItem
          v-for="(emp, index) in surrogate"
          :key="index"
          :item="{
            ...emp,
            border: '#ED1B26',
          }"
        />
      </div>
      <div
        id="grandChanld"
        class="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16 mx-auto"
      >
        <StructureItem
          v-for="(emp, index) in captain"
          :key="index"
          :item="{
            ...emp,
            border: '#0075BE',
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout/Header";
import StructureItem from "@/components/utils/StructureItem";
export default {
  name: "Structure",
  data() {
    return {
      data: [{}],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("fetchStructureData")
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    Structure() {
      return this.$store.getters.Structure;
    },
    head() {
      return this.Structure.filter(
        (emp) => emp.job_description_ar == "مدير عام"
      );
    },
    captain() {
      return this.Structure.filter((emp) => emp.job_description_ar == "نقيب");
    },
    surrogate() {
      return this.Structure.filter((emp) => emp.job_description_ar == "نائب");
    },
  },
  components: {
    Header,
    StructureItem,
  },
};
</script>
